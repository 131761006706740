import getData from "@/__main__/get-data.mjs";
import ValorantStatsAgents from "@/data-models/valorant-stats-agents.mjs";
import ValorantStatsMaps from "@/data-models/valorant-stats-maps.mjs";
import ValorantStatsWeapons from "@/data-models/valorant-stats-weapons.mjs";
import { getStats } from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import { getStatsParamsStr } from "@/game-val/stats-utils.mjs";

const constantOptions = {
  networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
};

async function fetchData([tab], searchParams) {
  const [content] = await fetchConstants();
  const promises = [];

  switch (tab) {
    case "maps": {
      const mapsParams = getStatsParamsStr(searchParams, "maps");
      mapsParams.sort();
      const mapsKey = btoa(mapsParams.toString());

      promises.push(
        getData(
          getStats(searchParams, content, "maps"),
          ValorantStatsMaps,
          ["val", "stats", "maps", mapsKey],
          constantOptions,
        ),
      );
      break;
    }
    case "agents": {
      const agentsParams = getStatsParamsStr(searchParams, "agents");
      agentsParams.sort();
      const agentsKey = btoa(agentsParams.toString());

      promises.push(
        getData(
          getStats(searchParams, content, "agents"),
          ValorantStatsAgents,
          ["val", "stats", "agents", agentsKey],
          constantOptions,
        ),
      );
      break;
    }
    case "weapons": {
      const weaponsParams = getStatsParamsStr(searchParams, "weapons");
      weaponsParams.sort();
      const weaponsKey = btoa(weaponsParams.toString());

      promises.push(
        getData(
          getStats(searchParams, content, "weapons"),
          ValorantStatsWeapons,
          ["val", "stats", "weapons", weaponsKey],
          constantOptions,
        ),
      );
      break;
    }
    default:
      break;
  }

  return Promise.all(promises);
}

export default fetchData;
